import React, {useContext, useEffect, useState} from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {Col, Row, Table} from 'antd';
import {LocaleContext} from '~src/context';
import {CalculationSubtype, CalculationType} from '~src/types';

interface IContentProps {
  calculationType: CalculationType;
  calculationSubtype: CalculationSubtype;
}

const Content: React.FC<IContentProps> = ({calculationType = 1, calculationSubtype = 1}) => {
  const {dispatch, materials} = useStoreon<IState, IEvents>('materials');
  const [currentPage, setCurrentPage] = useState(1);
  const {
    pages: {
      admin: {
        materials: {calculationTypes, tablePageSize},
      },
    },
  } = useContext(LocaleContext);
  useEffect(() => {
    const page = 1;
    dispatch('materials/fetch-materials', {
      calculationType,
      calculationSubtype,
      page,
      count: tablePageSize,
      orderBy: 'DESC',
    });
    setCurrentPage(page);
  }, [calculationType, calculationSubtype]);
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    dispatch('materials/fetch-materials', {
      calculationType,
      calculationSubtype,
      page,
      count: tablePageSize,
      orderBy: 'DESC',
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Table
          dataSource={materials.values}
          columns={(calculationTypes[`${calculationType}.${calculationSubtype}`] as any).columns}
          loading={materials.isLoading}
          pagination={
            materials.count > tablePageSize
              ? {
                  showSizeChanger: false,
                  total: materials.count,
                  onChange: handleChangePage,
                  current: currentPage,
                }
              : false
          }
        />
      </Col>
    </Row>
  );
};

export default Content;
